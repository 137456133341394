import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getAgentePerfis() {
      return new Promise((resolve, reject) => {
        axios.get('agente/perfil')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    enviarSinal(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('sinal', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
