<template>
  <div>
    <!-- ÁREA DE INPUT DO USUÁRIO -->
    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            title="1º Preencha os dados da operação"
          >
            <validation-observer ref="formSignal">
              <b-form @submit.prevent="submitForm">
                <b-row v-if="agentePerfis.length>1">
                  <b-col cols="6">
                    <b-form-group label="Perfil">
                      <validation-provider
                        #default="{ errors }"
                        name="perfil"
                        rules="required"
                      >
                        <b-form-select
                          v-model="sinal.agentePerfilTradeId"
                          :options="agentePerfis"
                          input-id="perfil"
                          text-field="nome"
                          value-field="id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label for="moeda">
                        Moeda
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Moeda"
                        :rules="{ required: true }"
                      >
                        <b-form-input
                          id="moeda"
                          v-model="sinal.moedaTrade"
                          @input="sinal.moedaTrade = sinal.moedaTrade.toUpperCase()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Paridade</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Moeda paridade"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          v-model="paridadeEscolhida"
                          :options="paridadeOptions"
                          input-id="paridade"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Tipo de compra</label>
                      <validation-provider
                        #default="{ errors }"
                        name="tipoOrdem"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          v-model="sinal.ordemTipo"
                          :options="tipoOrdensOptions"
                          input-id="tipoOrdem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="sinal.ordemTipo !== 'market'"
                    cols="6"
                  >
                    <b-form-group>
                      <label>Limite</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Limite"
                        :rules="{ required: sinal.ordemTipo === 'limit' || sinal.ordemTipo === 'stop_limit', regex: /^[+]?\d+(\.\d+)?$/ }"
                      >
                        <b-form-input
                          id="Limite"
                          v-model="sinal.valorCompra"
                          :disabled="sinal.ordemTipo=='market'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="sinal.ordemTipo === 'stop_limit'">
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Stop limite</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Stop limite"
                        :rules="{ required: sinal.ordemTipo === 'stop_limit', regex: /^[+]?\d+(\.\d+)?$/ }"
                      >
                        <b-form-input
                          id="valorCompraStop"
                          v-model="sinal.valorCompraStop"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Alvo 1</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Alvo 1"
                        :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/ }"
                      >
                        <b-form-input
                          id="alvo1"
                          v-model="sinal.alvo1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Alvo 2</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Alvo 2"
                        :rules="{ required: false, regex: /^[+]?\d+(\.\d+)?$/ }"
                      >
                        <b-form-input
                          id="alvo2"
                          v-model="sinal.alvo2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group label="Stop Loss">
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <b-form-checkbox
                            v-model="stopLossHabiltado"
                            plain
                            name="checkbox-input"
                          />
                        </b-input-group-prepend>
                        <validation-provider
                          #default="{ errors }"
                          name="Stop loss"
                          :rules="{ required: stopLossHabiltado }"
                        >
                          <b-form-input
                            id="stoploss"
                            v-model="sinal.stopLoss"
                            :disabled="!stopLossHabiltado"
                            :placeholder="stopLossHabiltado?'':'Sem stoploss'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <!-- ÁREA DE RESUMO DA OPERAÇÃO -->
        <b-col
          cols="12"
          lg="6"
        >
          <b-card title="2º Revise e envie para seus assinantes">
            <b-row>
              <b-col class="mb-2">
                <b-row class="mb-1">
                  <b-col>
                    <b-row>
                      <b-col
                        cols="12"
                        lg="4"
                      >
                        <h5>Moeda</h5>
                        <span>
                          <b-avatar
                            v-if="sinal.moedaTrade"
                            :src="getIcon(sinal.moedaTrade)"
                          /> <strong>{{ paridade }}</strong>
                        </span>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="mt-1">
                            <span class="mr-1"> Volume em 24h: </span>
                            <span v-if="!sinal.moedaTrade"><strong>--</strong></span>
                            <span v-else-if="ticker!=null && ticker.vol"><strong>{{ ticker.vol }}</strong></span>
                            <span v-else><b-spinner small /></span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <span class="mr-1">Variação em 24h:</span>
                            <span v-if="!sinal.moedaTrade"><strong>--</strong></span>
                            <div
                              v-else-if="ticker"
                              class="d-inline"
                            >
                              <span
                                :class="ticker.percent >0? 'text-success' : 'text-danger'"
                              >
                                <strong>{{ ticker.percent }}% </strong>
                              </span>
                              <feather-icon
                                :icon="ticker.percent>0 ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                                :class="[ticker.percent>0? 'text-success' : 'text-danger']"
                              />
                            </div>
                            <span v-else><b-spinner small /></span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="mb-1">
                    <h5>Perfil</h5>
                    <span v-if="sinal.agentePerfilTradeId">{{ agentePerfil }}</span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col class="mb-1">
                    <h5>Tipo de compra</h5>
                    <span v-if="sinal.ordemTipo">{{ tipoOrdemCompra }}</span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col class="mb-1" />
                </b-row>
                <b-row class="mb-1 mt-2">
                  <b-col>
                    <h5>Valor compra</h5>
                    <span v-if="valorCompra">{{ valorCompra }}</span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col>
                    <h5>Preço atual</h5>
                    <span v-if="!sinal.moedaTrade"><strong>--</strong></span>
                    <span v-else-if="ticker">{{ ticker.price }}</span>
                    <span v-else><b-spinner small /></span>
                  </b-col>
                  <b-col>
                    <h5>Status</h5>
                    <span v-if="!sinal.moedaTrade || !valorCompra"><strong>--</strong></span>
                    <div v-else-if="ticker!=null && ticker.price!=null && valorCompra">
                      <span
                        :class="parseFloat(ticker.price)>=parseFloat(valorCompra)? 'text-success' : 'text-danger'"
                      >
                        {{ percentDiferenca(valorCompra, ticker.price) }}%
                      </span>
                      <feather-icon
                        :icon="parseFloat(ticker.price)>=parseFloat(valorCompra)
                          ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                        :class="[parseFloat(ticker.price)>=parseFloat(valorCompra)? 'text-success' : 'text-danger']"
                      />
                    </div>
                    <span v-else><b-spinner small /></span>
                  </b-col>
                </b-row>
                <b-row class="mb-1 mt-2">
                  <b-col
                    lg="4"
                    cols="4"
                  >
                    <h5>Alvo 1</h5>
                    <span>{{ sinal.alvo1 }}</span>
                    <span v-if="sinal.alvo1">
                      <span
                        class="mr-1"
                        :class="parseFloat(valorCompra)<=parseFloat(sinal.alvo1)? 'text-success' : 'text-danger'"
                      >
                        ({{ percentDiferenca(valorCompra, sinal.alvo1) }})
                      </span>
                    </span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col
                    lg="4"
                    cols="4"
                  >
                    <h5>Alvo 2</h5>
                    <span>{{ sinal.alvo2 }}</span>
                    <span v-if="sinal.alvo2">
                      <span
                        class="mr-1"
                        :class="parseFloat(valorCompra)<=parseFloat(sinal.alvo2)? 'text-success' : 'text-danger'"
                      >
                        ({{ getPercentDiff(valorCompra, sinal.alvo2) }})
                      </span>
                    </span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col
                    lg="4"
                    cols="4"
                  >
                    <h5>Stop Loss</h5>
                    <span>{{ sinal.stopLoss }}</span>
                    <span v-if="sinal.stopLoss">
                      <span
                        class="mr-1"
                        :class="parseFloat(valorCompra)<=parseFloat(sinal.stopLoss)? 'text-success' : 'text-danger'"
                      >
                        ({{ getPercentDiff(valorCompra, sinal.stopLoss) }})
                      </span>
                    </span>
                    <span v-else>--</span>
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col>
                    <b-button
                      variant="primary"
                      type="submit"
                      @click.prevent="confirmarSalvar"
                    >
                      Enviar sinal
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BFormInput, BButton, BAvatar, BSpinner, BOverlay, BInputGroup, BInputGroupPrepend, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { subscribeSymbol } from '@/services/binance/spot/binance'
import { percentDiferenca } from '@/utils/filter'
import novaOperacaoModule from './novaOperacaoModule'

const NOVA_OPERACAO_AGENTE_SPOT_STORE_MODULE_NAME = 'nova-operacao-agente-spot'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BButton,
    BAvatar,
    BSpinner,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [utilsMixin],

  data() {
    return {
      loadingOverlay: false,
      agentePerfis: [],
      percentDiferenca,
      required,
      paridadeEscolhida: null,
      sinal: {
        moedaTrade: null,
        moedaParidade: null,
        ordemTipo: 'limit',
        agentePerfilTradeId: null,
        alvo1: null,
        alvo2: null,
        stopLoss: null,
        valorCompra: null,
      },

      stopLossHabiltado: true,
      alvoHabilitado: true,

      paridadeOptions: [
        { text: 'USDT', value: 'USDT' },
        { text: 'BTC', value: 'BTC' },
      ],

      tipoOrdensOptions: [
        { text: 'Limite Stop', value: 'stop_limit' },
        { text: 'Limite', value: 'limit' },
        { text: 'Mercado', value: 'market' },
      ],
    }
  },

  computed: {

    agentePerfil() {
      if (this.sinal.agentePerfilTradeId) {
        return this.agentePerfis.find(v => v.id === this.sinal.agentePerfilTradeId).nome
      }
      return null
    },

    tipoOrdemCompra() {
      if (this.sinal.ordemTipo) {
        return this.tipoOrdensOptions.find(v => v.value === this.sinal.ordemTipo).text
      }
      return null
    },

    paridade() {
      if (this.sinal.moedaTrade != null && this.paridadeEscolhida != null) {
        return this.sinal.moedaTrade + this.paridadeEscolhida
      }
      return ''
    },

    ticker() {
      if (this.paridade !== '') {
        const coin = this.paridade
        subscribeSymbol(coin)
        return this.$store.state.binanceSpot.tickers[coin]
      }
      return ''
    },

    valorCompra() {
      if (this.sinal.ordemTipo === 'limit' || this.sinal.ordemTipo === 'stop_limit') {
        return this.sinal.valorCompra
      }
      return this.ticker.price
    },

  },

  created() {
    if (!store.hasModule(NOVA_OPERACAO_AGENTE_SPOT_STORE_MODULE_NAME)) store.registerModule(NOVA_OPERACAO_AGENTE_SPOT_STORE_MODULE_NAME, novaOperacaoModule)
    this.getAgentePerfis()
  },

  beforeDestroy() {
    if (store.hasModule(NOVA_OPERACAO_AGENTE_SPOT_STORE_MODULE_NAME)) store.unregisterModule(NOVA_OPERACAO_AGENTE_SPOT_STORE_MODULE_NAME)
  },

  methods: {
    getAgentePerfis() {
      this.$store.dispatch('nova-operacao-agente-spot/getAgentePerfis')
        .then(response => {
          this.agentePerfis = response.data.filter(a => a.produto === 'SPOT')
          if (this.agentePerfis.length === 1) {
            this.sinal.agentePerfilTradeId = this.agentePerfis[0].id
          }
        })
    },

    confirmarSalvar() {
      this.$swal({
        title: 'Confirmar as alterações',
        text: 'Revise os valores. Ao clicar no botão verde, você vai confirmar o envio do seu sinal e emitir uma ordem de compra.',
        icon: 'warning',
        confirmButtonText: 'Ok, está tudo certo!',
        showCancelButton: true,
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.submitForm()
        }
      })
    },

    clearForm() {
      this.sinal.moedaTrade = null
      this.sinal.moedaParidade = null
      this.sinal.ordemTipo = 'limit'
      this.sinal.alvo1 = null
      this.sinal.alvo2 = null
      this.sinal.stopLoss = null
      this.sinal.valorCompra = null
      this.stopLossHabiltado = true
      this.$refs.formSignal.reset()
    },

    submitForm() {
      this.$refs.formSignal.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.sinal.moedaParidade = this.sinal.moedaTrade + this.paridadeEscolhida

          if (this.stopLossHabiltado === false) {
            this.sinal.stopLoss = 'na'
          }

          if (this.alvoHabilitado === false) {
            this.sinal.alvo1 = 'na'
          }

          this.$store.dispatch('nova-operacao-agente-spot/enviarSinal', this.sinal)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Sucesso',
                html: 'Sinal enviado com sucesso',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              })
              this.clearForm()
            })
            .catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.data[0].erro,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.loadingOverlay = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
